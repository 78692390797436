import * as React from "react";
import { AuthenticationApi, NemidApi, SignicatAuthMethod } from "../lib-api";
import { apiConfiguration } from "../api-configuration";
import { useFormikContext } from "formik";
import { useCookies } from "react-cookie";
import { Notification } from "./StepThree";
import styled from "styled-components";


const api = new NemidApi(apiConfiguration);


export const SignBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    margin: 20px auto;
    border-radius: 4px;
    border: 1px solid black;
    background-color: transparent;
    padding: 20px 0;
    img{
        width: 65px;
    }
    @media only screen and (max-width: 500px) {
        width: 100%;
    }
`

export const MitIDBtn = styled(SignBtn)
`
    border-color: transparent;
    background-color: #0061e6;
    color: white;
    font-weight: bold;
    &:hover{
    background-color: #004cb8;
    }
`

export const SignCon = styled.div`
    min-height: 420px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`





const StepSign: React.FunctionComponent = () => {
    const { values, submitForm, setFieldValue } = useFormikContext<any>();
    const urlParams = new URLSearchParams(window.location.search);
    var authApi = new AuthenticationApi(apiConfiguration);
    const [coApp, setCoApp] = React.useState((urlParams.get('coapp') === 'true') ? true : (urlParams.get('coapp') === 'false') ? false : null)
    const [valError, setValError] = React.useState((urlParams.get('validationfailed') === 'true') ? true : (urlParams.get('validationfailed') === 'false') ? false : null)
    const [noMatch, setNoMatch] = React.useState((urlParams.get('nomatch') === 'true') ? true : (urlParams.get('nomatch') === 'false') ? false : null)
    function SignWithId(signType) {
        var returnURL = "/redirect";
        authApi.getAuthenticationRedirectUri({
            method: signType,
            returnUrl: returnURL
        }).then(response => {
            location.href = response.url;
        });
    }
    return (
        <SignCon>
            <p style={{ fontSize: "12px", marginTop: "-12px" }}>Vi bruger kun oplysningerne fra NemID og MitID til at validere, at dine tidligere indtastede personlige oplysninger er korrekte.</p>

            {coApp != null && valError != null && noMatch != null &&
                <Notification>
                    {coApp == true && valError == false && noMatch == false &&
                        <p>Færdiggør ansøgningen med din medansøger</p>
                    }
                    {valError == true && noMatch == false &&
                        <p>Noget gik galt med valideringen. Prøv igen eller kontakt kundeservice</p>
                    }
                    {valError == true && noMatch == true &&
                        <div>
                            <p>Dit indtastede CPR-nummer passede ikke med det CPR-nummer vi fik tilbage fra NemID/MitID{coApp == true ? "med medansøgeren." : "." } <br /> Hvis dette ikke er tilfældet, venligst kontakt kundeservice</p>
                        </div>
                    }

                </Notification>
            }
            {/*<SignBtn onClick={() => SignWithId(SignicatAuthMethod.NUMBER_1)}>*/}
            {/*    <img src="../images/nemid.svg" />*/}
            {/*    <span>Færdiggør med NemID</span>*/}
            {/*</SignBtn>*/}
            <MitIDBtn onClick={() => SignWithId(SignicatAuthMethod.NUMBER_0)}>
                <img src="../images/mitid_btn_logo.png" />
                <span>Færdiggør med MitID</span>
            </MitIDBtn>
        </SignCon >

    )
}

export default StepSign;