import { Formik } from 'formik';
import React from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import { apiConfiguration } from "../api-configuration";
import FormContainer, { NextPopUp } from "../Form/FormContainer";
import { GetAddressMonthText } from "../GetAppData";
import { ApplicationApi } from "../lib-api/apis/ApplicationApi";
import { LoanBrokerApi } from "../lib-api/apis/LoanBrokerApi";
import { Car } from "../Models/Car";
import { Child } from "../Models/Child";
import { SamleLaan } from "../Models/Samlelaan";
import { trackEvent } from "../util/gtm";
import { CivilStatus, HouseType, LoanReason } from "./Enums";
import { validationSchema } from "./validation";

var url = window.location.pathname;
var initAmount = 150000;
var data = (url.match(/(\d+)/g));
if (data != null) {
    initAmount = parseInt(data[0]);
}

const applicationForm = document.getElementsByTagName("applicationForm");
if (applicationForm.length > 0) {
    const element = applicationForm[0];
    const samle = element.getAttribute("data-samlelaan");
    if (samle == "laanetyper/samlelaan") {
        var samlebool = true;
    }
    else {
        var samlebool = false;

    }
    if (samle == "laanetyper/billaan") {
        var bilBool = true;
    }
    else {
        var bilBool = false;
    }

}

const MainContainer = styled.div`
    text-align: center;
    color: #FCFCFC;
    padding-bottom: 75px;
    padding-top: 20px;
    min-height: 1300px

@media only screen and (max-width: 600px) {
            min-height: 100vh:

    }
`
export const vw = window.screen.width;

const Headline = styled.h1`
font-size: 28px;
line-height: 55px;
margin-top: 120px;
text-align: left;
width: 80%;
font-weight: 600;
@media only screen and (max-width: 1000px) {
        margin-top: 0;

    }
@media only screen and (max-width: 600px) {
        font-size: 21px;
        text-align: center;
        margin: auto;
        line-height: 30px;
        margin-top: 30px;
    }

`;

const HighlighedWord = styled.span`
font-weight: 800;
color: #14C6B1;
text-shadow: 0px 3px 6px #0000004D;
font-size: 35px;
@media only screen and (max-width: 600px) {
        font-size: 21px;

    }
`

const SubHeadline = styled.h3`
font-size: 17px;
line-height: 40px;
text-align: left;
font-weight: 300;
width: 80%;
@media only screen and (max-width: 600px) {
        display: none;
    }


`

const OrangeHeadline = styled("span")({
    color: "#EC5D35"
});

export const Container = styled.div`
    background-color: #FFFFFF;
    borderRadius: 5px;
    marginTop: 50px;
    box-shadow: 0px 10px 15px 0px #00000029;
    mos-box-shadow: 0px 10px 15px 0px #00000029;
    webkit-mox-shadow: 0px 10px 15px 0px #00000029;
    position: relative;
`

const ResultContainer = styled.div`
   @media (max-width: 768px) {
        margin-top:0;
  }
`

export const Error = styled.div`
    font-style: italic;
    color: #E76F51;
    font-size: 11px;
    left: 7px;
    margin-top: 2px;
    padding: 0 !important;
    div{
    padding: 0 !important;
    }

    @media only screen and (max-width: 600px) {
    margin-top: 0px;
    }
@media only screen and (max-width: 400px) {
    margin-top: 0px;
    left: 0;
    }
`

export const HeadlineContainer = styled("div")`
    img{
    width: 40%;
    height: auto;
    margin-top: 30px;
    @media only screen and (max-width: 600px) {
    margin: auto;
    margin-bottom: 40px;
    margin-left: 30%;
    margin-right: 30%;
    margin-top: 30px;
    }
    }
    text-align: left;

`

export const GoToBtn = styled.button`
position: absolute;
left: 20px;
margin-top: 5px;
top: 125px;
`

export const LoaderCon = styled.div`
position: fixed;
width: 100vw;
left:0;
top: 0;
height: 100vh;
background-color: rgba(134, 168, 181, 0.3);
z-index: 25;

`

export const Loader = styled.div`
position: fixed;
width: 350px;
height: 300px;
left: 0;
right: 0;
top: 0;
bottom: 0;
margin-top: auto;
margin-bottom: auto;
margin-left: auto;
margin-right: auto;
padding: 25px;
text-align: center;
h4{
font-size: 18px;
}
border-radius: 11px;
background-color: white;
img{
width: 50%;
}
`

export const InUseCon = styled.div`
position: fixed;
width: 100vw;
left:0;
top: 0;
height: 100vh;
background-color: rgba(0, 0, 0, 0.3);
z-index: 25;
`

export const InUse = styled.div`
position: fixed;
width: 400px;
height: 500px;
left: 0;
right: 0;
top: 0;
bottom: 0;
margin-top: auto;
margin-bottom: auto;
margin-left: auto;
margin-right: auto;
padding: 35px;
text-align: center;
border-radius: 11px;
background-color: white;

h4{
font-size: 26px;
font-weight: 600;
margin: 15px 0;
color: #3C5F6D;
}

img{
width: 40%;
}

p{
color: #3C5F6D;
font-size: 14px;
text-align: left;
width: 90%;
margin: auto;
    @media only screen and (max-width: 500px) {
    font-size: 13px;
    width: 94%;

}

}


    @media only screen and (max-width: 600px) {
    width: 94%;
    height: 90vh; 
    
}

`

export interface FormValues {
    acceptMail: boolean;
    step: number;
    isSamleLaan: boolean;
    loanAmount: number;
    inputAmount: number;
    email: string;
    phoneNumber: string;
    foreName: string;
    surName: string;
    startAmount: number;
    durationYears: number;
    cpr: string;
    coForeName: string;
    coSurName: string;
    coEmail: string;
    coCpr: string;
    maritalStatus: string;
    rent: string;
    rki: number;
    dwelling: string;
    children: Child[];
    reasonOfLoan: string;
    coApplicant: boolean;
    regNr: string;
    termsOfService: boolean;
    marketing: boolean;
    creditCheck: boolean;
    eSkatConsent: boolean;
    insurance: boolean;
    cars: Car[];
    hasOldLoan: number;
    sharedExpenses: number;
    samleLaan: SamleLaan[]; 
    samleLaanEkstraLoan?: boolean;
    // Nye
    coPhoneNumber: string;
    timeOnAdress: Date;
    houseRentalAmount: number;
    householdHeatingExpense: number;
    householdUtilitiesExpense: number;
    householdDigitalMediaExpense: number;
    householdInsurance: number;
    householdCarsLeasingExpense: number;
    householdCarsNumber: number;
    householdTransportationOtherExpense: number;
    employmentType: number;
    coEmploymentType: number;
    employerIdentificationNumber: number;
    employmentSinceDate: Date;
    employmentSinceMonth: number;
    employmentSinceMonthText: string;
    employmentSinceYear: number;
    coEmploymentSinceDate: Date;
    coEmploymentSinceMonth: number;
    coEmploymentSinceMonthText: string;
    coEmploymentSinceYear: number;
    jobInsuranceStatus: number;
    coJobInsuranceStatus: number;
    incomeGrossAmount: number;
    coIncomeGrossAmount: number;
    incomeNetAmount: number;
    coIncomeNetAmount: number;
    incomeOtherType: number;
    coIncomeOtherType: number;
    incomeOtherAmount: number;
    coIncomeOtherAmount: number;
    householdDiscretionaryIncome: number;
    householdExpenseOtherDebtAmount: number;
    debtSinceLastTexReport: number;
    coDebtSinceLastTaxReport: number;
    creditCardStatus: number;
    citizenship: number;
    coCitizenship: number;
    permitToStay: number;
    coPermitToStay: number;
    permitTypeTemporary: string;
    coPermitTypeTemporary: string;
    permitNumber: string;
    coPermitNumber: string;
    permitTypePermanent: string;
    coPermitTypePermanent: string;
    permitForeignerNumber: string;
    coPermitForeignerNumber: string;
    permitExpiryDate: Date;
    coPermitExpiryDate: Date;
    bankSinceYear: number;
    creditPaymentMethod: number;
    creditPaymentBankCode: string;
    creditPaymentAccountNumber: string;
    creditLookupConsentStatus: number;
    coCreditLookupConsentStatus: number;
    jobInsuranceSince: Date;
    jobInsuranceSinceYear: number;
    loanReason: string;
    editLoan: boolean;
    ownVacationHouse: string;
    numOfChildren: number;
    rkiMember: string;
    mastercardStatus: number;
    debtSinceJan: number;
    coDebtSinceJan: number;
    carLoan: number;
    carLoanRest: number;
    carLoanExpense: number;
    studyLoan: number;
    studyLoanRest: number;
    studyLoanExpense: number;
    consumerLoan: number;
    consumerLoanRest: number;
    consumerLoanExpense: number;
    otherLoan: number;
    householdOtherLoanRest: number;
    householdOtherLoanExpense: number;
    otherDebt: number;
    otherDebtExpense: number;
    consent: boolean;
    cprTest: string;
    coCprTest: string;
    addressSince: Date;
    addressSinceMonth: number;
    addressSinceMonthText: string;
    addressSinceYear: number;
    otherReason: string;
    isDone: boolean;
    cprValidated: boolean;
    childSupport: number;
    childExpense: number;
    otherChildSupport: number;
    daycare: number;
    houseSupport: number;
    hasHouseSupport: number;
    returning: boolean;
    permitExpiryYear: number;
    permitExpiryMonth: number;
    permitExpiryMonthText: string;
    coPermitExpiryYear: number;
    coPermitExpiryMonth: number;
    coPermitExpiryMonthText: string;
    samleLoanAmount: number;
    inSession: boolean;
    citizenshipCategory: number;
    ownerExpense: number;
    facitConsent: boolean;
    hasOtherDebt: number;
    livedSinceYear: number;
    livedSinceMonth: number;
    livedSinceMonthText: string;
    livedSinceDate: Date;
    cpr2Returning: boolean;
    carPrice: number;
    carPayment: number;
    carYear: number;
    carLoanAmount: number;
    appId: any;
    aseConsent: boolean;
    lowCarPrice: boolean;
    isFast: boolean;
    pid: string;
    coPid: string;
    getCoNemidSign: boolean;
    carStartup: number;
    publicDebt: number;
    publicDebtRest: number;
    publicDebtExpense: number;
    householdShare: number;
    oetAccept: boolean;
    isCarLoan: boolean;
    isDanish: boolean;
    ancestralHome: string;
    carPaymentPercentage: number;

}

const ApplicationForm: React.FunctionComponent = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
    var applicationApi = new ApplicationApi(apiConfiguration);
    const [showLoader, setShowLoader] = React.useState(true);
    const [showWarning, setShowWarning] = React.useState(false);
    var currentYear = new Date().getFullYear()

    const initialValues: FormValues = {
        acceptMail: false,
        step: 1,
        isSamleLaan: samlebool,
        loanAmount: initAmount,
        inputAmount: initAmount,
        email: "",
        phoneNumber: "",
        foreName: "",
        surName: "",
        startAmount: 0,
        durationYears: 10,
        cpr: "standard",
        coForeName: "",
        coSurName: "",
        coEmail: "",
        coCpr: "standard",
        coCprTest: "",
        maritalStatus: "",
        rent: "",
        rki: -1,
        dwelling: "",
        children: [],
        reasonOfLoan: "",
        coApplicant: false,
        regNr: "",
        termsOfService: false,
        marketing: false,
        creditCheck: false,
        eSkatConsent: false,
        insurance: false,
        cars: [],
        hasOldLoan: 0,
        sharedExpenses: 0,
        samleLaan: [new SamleLaan],
        coPhoneNumber: "",
        timeOnAdress: new Date,
        houseRentalAmount: 0,
        householdHeatingExpense: -1,
        householdUtilitiesExpense: -1,
        householdDigitalMediaExpense: -1,
        householdInsurance: -1,
        householdCarsLeasingExpense: 0,
        householdCarsNumber: 0,
        householdTransportationOtherExpense: -1,
        employmentType: -1,
        coEmploymentType: -1,
        employerIdentificationNumber: 0,
        employmentSinceDate: new Date,
        employmentSinceMonth: 0,
        employmentSinceMonthText: "",
        employmentSinceYear: 0,
        coEmploymentSinceDate: new Date,
        coEmploymentSinceMonthText: "",
        coEmploymentSinceMonth: 0,
        coEmploymentSinceYear: 0,
        jobInsuranceStatus: -1,
        coJobInsuranceStatus: -1,
        incomeGrossAmount: 0,
        coIncomeGrossAmount: 0,
        incomeNetAmount: 0,
        coIncomeNetAmount: 0,
        incomeOtherType: -1,
        coIncomeOtherType: -1,
        incomeOtherAmount: 0,
        coIncomeOtherAmount: 0,
        householdDiscretionaryIncome: -1,
        householdExpenseOtherDebtAmount: 0,
        debtSinceLastTexReport: 0,
        coDebtSinceLastTaxReport: 0,
        creditCardStatus: -1,
        citizenship: 1,
        coCitizenship: -1,
        permitToStay: 0,
        coPermitToStay: 0,
        permitTypeTemporary: "",
        coPermitTypeTemporary: "",
        permitNumber: "",
        coPermitNumber: "",
        permitTypePermanent: "",
        coPermitTypePermanent: "",
        permitForeignerNumber: "",
        coPermitForeignerNumber: "",
        permitExpiryDate: new Date,
        permitExpiryYear: 0,
        permitExpiryMonth: 0,
        permitExpiryMonthText: "",
        coPermitExpiryDate: new Date,
        coPermitExpiryYear: 0,
        coPermitExpiryMonth: 0,
        coPermitExpiryMonthText: "",
        bankSinceYear: 0,
        creditPaymentMethod: 2,
        creditPaymentBankCode: "",
        creditPaymentAccountNumber: "",
        creditLookupConsentStatus: 0,
        coCreditLookupConsentStatus: 0,
        jobInsuranceSince: new Date,
        jobInsuranceSinceYear: 0,
        loanReason: "",
        editLoan: false,
        ownVacationHouse: "",
        numOfChildren: 0,
        rkiMember: "",
        mastercardStatus: -1,
        debtSinceJan: -1,
        coDebtSinceJan: -1,
        carLoan: -1,
        carLoanRest: 0,
        carLoanExpense: -1,
        studyLoan: -1,
        studyLoanRest: 0,
        studyLoanExpense: -1,
        consumerLoan: -1,
        consumerLoanRest: 0,
        consumerLoanExpense: -1,
        otherLoan: -1,
        householdOtherLoanRest: 0,
        householdOtherLoanExpense: -1,
        otherDebt: 0,
        otherDebtExpense: -1,
        consent: false,
        cprTest: "",
        addressSince: new Date,
        addressSinceMonth: 0,
        addressSinceMonthText: "",
        addressSinceYear: 0,
        otherReason: "",
        isDone: false,
        cprValidated: false,
        childSupport: 0,
        childExpense: 0,
        otherChildSupport: -1,
        daycare: -1,
        houseSupport: 0,
        hasHouseSupport: -1,
        returning: false,
        samleLoanAmount: 0,
        inSession: false,
        citizenshipCategory: 0,
        ownerExpense: 0,
        facitConsent: false,
        hasOtherDebt: -1,
        livedSinceYear: 0,
        livedSinceMonth: 0,
        livedSinceMonthText: "",
        livedSinceDate: new Date,
        cpr2Returning: false,
        carPrice: 150000,
        carPayment: 15000,
        carYear: currentYear,
        carLoanAmount: 135000,
        appId: null,
        aseConsent: false,
        lowCarPrice: false,
        isFast: false,
        pid: "",
        coPid: "",
        getCoNemidSign: false,
        carStartup: 0,
        publicDebt: -1,
        publicDebtRest: 0,
        publicDebtExpense: -1,
        householdShare: -1,
        oetAccept: false,
        isCarLoan: bilBool,
        isDanish: true,
        ancestralHome: "DK",
        carPaymentPercentage: 0.1,
    };

    const [appId, setAppId] = React.useState("");


    return (

        <Formik  
            initialValues={ initialValues} 
            onSubmit={async (values, helpers) => {  
                var api = new LoanBrokerApi(apiConfiguration);
                window.scrollTo(0, 0);
                values.employmentSinceDate = new Date(values.employmentSinceYear, values.employmentSinceMonth - 1)
                if (values.coApplicant == true)
                values.coEmploymentSinceDate = new Date(values.coEmploymentSinceYear, values.coEmploymentSinceMonth - 1)
                values.jobInsuranceSince = new Date(values.jobInsuranceSinceYear, 1)
                values.permitExpiryDate = new Date(values.permitExpiryYear, values.permitExpiryMonth, 1);
                values.livedSinceDate = new Date(values.livedSinceYear, values.livedSinceMonth, 15);
                values.addressSince = new Date(values.addressSinceYear, values.addressSinceMonth - 1)
                if (values.step == 3 && values.coApplicant == true) {
                    values.cpr2Returning = true;
                }
                if (values.step == 5) {
                    if (values.coApplicant == true) {
                        values.coPermitExpiryDate = new Date(values.coPermitExpiryYear, values.coPermitExpiryMonth, 1);
                    }
                }
                console.log(values);

                /* ============ only add this if you want to skip nemid/mitid ============== */
                //if (values.step >= 9) {
                //    values.isDone = true;
                //    var response;
                //    response = await api.gettSendApplication({ applicationBody: values });
                //    var Id = response.replaceAll('"', '');
                //    trackEvent(
                //        "Indsend",
                //        Id,
                //        "Step: " + (values.step).toString()
                //    )
                //    setCookie("Validated", Id, { path: "/", maxAge: 1800 })
                //    removeCookie("NotFinished");
                //}

                if (values.step == 1 && values.loanReason == LoanReason.Samlelaan && values.isSamleLaan == false) {
                    document.getElementById('formTop').scrollIntoView();
                    values.isSamleLaan = true;
                }

                else if (values.step >= 3) {
                    console.log("MASTERCARD", values.mastercardStatus)
                    const updateApplication = await applicationApi.updateApplication({
                        applicationBody: values,
                    })
                    trackEvent(
                        "Næste",
                        values.appId,
                        "Step: " + (values.step).toString()
                    )

                    values.step++;
                    window.scrollTo({ top: 0 })
                }
                else if (values.step == 2 && values.inSession == false) {
                    const application = await applicationApi.apiApplicationsGetApplicationPut({
                        cpr: values.cpr,
                        phone: values.phoneNumber
                    })
                    if (application == "") {
                        var response;
                       response = await applicationApi.apiApplicationsCreateApplicationPost({
                       applicationBody: values,
                           url: window.location.protocol + "//" + window.location.host + window.location.pathname
                       })
                        values.step++;
                        values.returning = true;
                        values.inSession = true;
                        var Id = response.replaceAll('"', '');
                        values.appId = Id;

                        trackEvent(
                            "Næste",
                            Id,
                            "Step: " + (values.step).toString()
                        )

                        trackEvent(
                            "Oekonomitjek Permission",
                            values.oetAccept == true ? "Ja" : "Nej",
                            ""
                        )
                        setCookie("NotFinished", Id, { path: "/", maxAge: 18000 })
                }
                    else if(values.inSession == false) {
                        setShowWarning(true);
                    }
                }
                else {
                    values.step++;
                }


                if (values.step == 2 && values.loanReason != LoanReason.Samlelaan) {                    
                    //if (vw > 500) {
                    //    document.getElementById('step2').scrollIntoView({
                    //        block: "center"
                    //    });
                    //}
                    //else {
                    //    document.getElementById('mobile-step2').scrollIntoView();
                    //}
                }
                else {
                    document.getElementById('formTop').scrollIntoView();
                }
                var params = window.location.search;
                console.log(params);
                var mark;
                var regex = /((&|\?)step=)[0-9]{1,2}/gi;
                if (params != "") {
                    if (params.includes("step")) {
                        params = params.replace(regex, "$1" + values.step);

                    }
                    else {
                        mark = "&step=" + values.step;

                    }
                }
                else {
                    mark = "?step=" + values.step;
                };

                if (mark == undefined)
                    mark = "";

                var stepQuery = window.location.protocol + "//" + window.location.host + window.location.pathname + params + mark;
                window.history.pushState({ path: stepQuery }, '', stepQuery)
                helpers.resetForm({ values })
                var id;
                id = await applicationApi.apiApplicationsGetApplicationPut({
                    cpr: values.cpr,
                    phone: values.phoneNumber
                })

                id = id.replaceAll('"', '');


            }}
            validationSchema={validationSchema}
        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                    isValid,
                    submitCount,
                    setFieldValue,
                    isValidating
                } = props;
                function locationHasChanged() {
                    const urlParams = new URLSearchParams(window.location.search);
                    console.log(urlParams);
                    var myParam = urlParams.get('step');
                    if (myParam == null) {
                        setFieldValue("step", 1);
                    }
                    else {
                        setFieldValue("step", myParam);
                    }
                }

                // GETS DATA WITH HELP OF COOKIE
                var cookie = cookies.NotFinished;

                async function GetAppById() {
                    if (cookies.NotFinished != "") {
                        const urlParams = new URLSearchParams(window.location.search);
                        const coApp = urlParams.get('coApp');
                        const validationError = urlParams.get('validationfailed');
                        const nomatch = urlParams.get('nomatch');

                        setFieldValue("inSession", true);
                        setFieldValue("returning", true);
                        var currentApp = await applicationApi.getApplicationById({
                            id: cookie
                        });
                        if ((validationError != null && validationError != "") && (nomatch != null && nomatch != "") && (coApp != null && coApp != "")) {
                            setFieldValue("step", 10);
                            values.step = 10;
                        }
                        else {
                            setFieldValue("step", currentApp.application.step + 1);
                            values.step = currentApp.application.step + 1;
                        }
                        var params = window.location.search;
                        var mark;
                        var regex = /((&|\?)step=)[0-9]{1,2}/gi;
                        if (params != "") {
                            if (params.includes("step")) {
                                params = params.replace(regex, "$1" + values.step);

                            }
                            else {
                                mark = "&step=" + values.step;

                            }
                        }
                        else {
                            mark = "?step=" + values.step;
                        };

                        if (mark == undefined)
                            mark = "";

                        var stepQuery = window.location.protocol + "//" + window.location.host + window.location.pathname + params + mark;
                        window.history.pushState({ path: stepQuery }, '', stepQuery)
                        setFieldValue("isCarLoan", currentApp.application.isCarLoan);
                        if (currentApp.application.step >= 2) {
                            setFieldValue("foreName", currentApp.application.name.split(" ")[0]);
                            setFieldValue("surName", currentApp.application.name.substr(currentApp.application.name.indexOf(" ") + 1));
                            setFieldValue("phoneNumber", currentApp.application.phoneNumber);
                            setFieldValue("cpr", currentApp.application.cpr);
                            setFieldValue("cprTest", currentApp.application.cpr);
                            setFieldValue("email", currentApp.application.email);
                            setFieldValue("acceptMail", currentApp.application.marketingConsent);
                            setFieldValue("loanAmount", currentApp.application.loanAmount);
                            setFieldValue("durationYears", currentApp.application.durationMonths / 12);
                            setFieldValue("loanReason", currentApp.application.reasonOfLoan);
                            setFieldValue("rki", 0);
                            if (currentApp.application.isCarLoan) {
                                setFieldValue("carPayment", currentApp.carInfo.carPayment);
                                setFieldValue("carPrice", currentApp.carInfo.carPrice);
                                setFieldValue("carPaymentPercentage", currentApp.carInfo.carPaymentPercentage/100)
                            }
                            if (currentApp.application.otherReason != "") {
                                setFieldValue("otherReason", currentApp.application.otherReason);
                            }
                        }

                        if (currentApp.application.martialStatus != "" && currentApp.application.step >= 3) {
                            setFieldValue("cpr2Returning", true);

                            setFieldValue("maritalStatus", currentApp.application.martialStatus);
                            setFieldValue("dwelling", currentApp.application.dwelling);
                            if (currentApp.application.dwelling != HouseType.Ejer && currentApp.application.dwelling != HouseType.Hus) {
                                setFieldValue("houseRentalAmount", currentApp.application.housingExpense);
                            }
                            else {
                                setFieldValue("ownerExpense", currentApp.application.housingExpense);
                            }
                            setFieldValue("addressSince", currentApp.application.addressSince)
                            setFieldValue("addressSinceMonth", currentApp.application.addressSince.getMonth() + 2)
                            setFieldValue("addressSinceMonthText", GetAddressMonthText(currentApp.application.addressSince.getMonth() + 2));
                            setFieldValue("addressSinceYear", currentApp.application.addressSince.getFullYear())
                            if (currentApp.application.coApplicant != null) {
                                setFieldValue("coApplicant", true);
                                setFieldValue("coForeName", currentApp.application.coApplicant.name.split(" ")[0]);
                                setFieldValue("coSurName", currentApp.application.coApplicant.name.substr(currentApp.application.coApplicant.name.indexOf(" ") + 1));
                                setFieldValue("coPhoneNumber", currentApp.application.coApplicant.phoneNumber);
                                setFieldValue("coEmail", currentApp.application.coApplicant.email);
                                setFieldValue("coCpr", currentApp.application.coApplicant.cpr);
                            }
                        }

                        if (currentApp.application.houseHoldHeatingExpense > -1 && currentApp.application.step >= 3) {
                            setFieldValue("householdHeatingExpense", currentApp.application.houseHoldHeatingExpense);
                            setFieldValue("householdUtilitiesExpense", currentApp.application.householdUtilitiesExpense);
                            setFieldValue("householdDigitalMediaExpense", currentApp.application.householdDigitalMediaExpense);
                            setFieldValue("houseSupport", currentApp.application.houseSupport);
                            setFieldValue("householdShare", currentApp.application.householdShare);
                            if (currentApp.application.houseSupport > 0) {
                                setFieldValue("hasHouseSupport", 1)
                            }
                            else {
                                setFieldValue("hasHouseSupport", 0)
                            }
                            setFieldValue("ownVacationHouse", currentApp.application.ownVacationHouse);
                            setFieldValue("employmentType", currentApp.application.applicant.employment);
                            setFieldValue("employerIdentificationNumber", currentApp.application.cvr);
                            setFieldValue("employmentSince", currentApp.application.applicant.employmentSince)
                            setFieldValue("employmentSinceMonth", currentApp.application.applicant.employmentSince.getMonth() + 2)
                            setFieldValue("employmentSinceMonthText", GetAddressMonthText(currentApp.application.applicant.employmentSince.getMonth() + 2));
                            setFieldValue("employmentSinceYear", currentApp.application.applicant.employmentSince.getFullYear())
                            if (currentApp.application.coApplicant != null) {
                                setFieldValue("coEmploymentSince", currentApp.application.coApplicant.employmentSince)
                                setFieldValue("coEmploymentSinceMonth", currentApp.application.coApplicant.employmentSince.getMonth() + 2)
                                setFieldValue("coEmploymentSinceMonthText", GetAddressMonthText(currentApp.application.coApplicant.employmentSince.getMonth() + 2));
                                setFieldValue("coEmploymentSinceYear", currentApp.application.coApplicant.employmentSince.getFullYear())
                                setFieldValue("coEmploymentType", currentApp.application.coApplicant.employment);

                            }
                        }
                        if (currentApp.application.applicant.jobInsurance > -1 && currentApp.application.step >= 3) {
                            setFieldValue("numOfChildren", currentApp.application.nrOfChildren);
                            for (let array = values.children, i = 0; i < currentApp.application.nrOfChildren; i++) {
                                var a: Child = {
                                    age: currentApp.children[i].age,
                                }
                                array.push(a)

                            }
                            if (currentApp.application.nrOfChildren > 0) {
                                setFieldValue("childSupport", currentApp.application.childSupport);
                                { currentApp.application.childSupport > 0 ? setFieldValue("otherChildSupport", 1) : setFieldValue("otherChildSupport", 0) }
                                setFieldValue("childExpense", currentApp.application.childExpense)
                                { currentApp.application.childExpense > 0 ? setFieldValue("daycare", 1) : setFieldValue("daycare", 0) }
                            }
                            setFieldValue("jobInsuranceStatus", currentApp.application.applicant.jobInsurance);
                            { currentApp.application.applicant.jobInsurance == 1 && setFieldValue("jobInsuranceSince", currentApp.application.jobInsuranceSince); setFieldValue("jobInsuranceSinceYear", currentApp.application.jobInsuranceSince.getFullYear()) }
                            setFieldValue("rkiMember", currentApp.application.rkiMember);
                            setFieldValue("citizenship", currentApp.application.applicant.citizenship);
                            if (currentApp.application.applicant.citizenship == 0) {
                                setFieldValue("permitToStay", currentApp.application.applicant.citizenshipPermit);
                                setFieldValue("permitForeignerNumber", currentApp.application.applicant.foreignNumber);
                                if (currentApp.application.applicant.citizenshipPermit == 1) {
                                    setFieldValue("permitTypeTemporary", currentApp.application.applicant.citizenshipTempPermit);
                                    setFieldValue("permitNumber", currentApp.application.applicant.citizenshipSerialNumber);
                                    setFieldValue("permitExpiryDate", currentApp.application.applicant.citizenshipTempExpire);
                                    setFieldValue("permitExpiryMonth", currentApp.application.applicant.citizenshipTempExpire.getMonth() + 2)
                                    setFieldValue("permitExpiryMonthText", GetAddressMonthText(currentApp.application.applicant.citizenshipTempExpire.getMonth() + 2));
                                    setFieldValue("permitExpiryYear", currentApp.application.applicant.citizenshipTempExpire.getFullYear())
                                }
                                else if (currentApp.application.applicant.citizenshipPermit == 2) {
                                    setFieldValue("permitTypePermanent", currentApp.application.applicant.citizenshipPermPermit);
                                    setFieldValue("permitNumber", currentApp.application.applicant.citizenshipSerialNumber);
                                }
                            }
                            if (currentApp.application.coApplicant != null) {
                                setFieldValue("coJobInsuranceStatus", currentApp.application.coApplicant.jobInsurance);
                                setFieldValue("coCitizenship", currentApp.application.coApplicant.citizenship);

                                if (currentApp.application.coApplicant.citizenship == 0) {
                                    setFieldValue("coPermitToStay", currentApp.application.coApplicant.citizenshipPermit);
                                    setFieldValue("coPermitForeignerNumber", currentApp.application.coApplicant.foreignNumber);
                                    if (currentApp.application.coApplicant.citizenshipPermit == 1) {
                                        setFieldValue("coPermitTypeTemporary", currentApp.application.coApplicant.citizenshipTempPermit);
                                        setFieldValue("coPermitNumber", currentApp.application.coApplicant.citizenshipSerialNumber);
                                        setFieldValue("coPermitExpiryDate", currentApp.application.coApplicant.citizenshipTempExpire);
                                        setFieldValue("coPermitExpiryMonth", currentApp.application.coApplicant.citizenshipTempExpire.getMonth() + 2)
                                        setFieldValue("coPermitExpiryMonthText", GetAddressMonthText(currentApp.application.coApplicant.citizenshipTempExpire.getMonth() + 2));
                                        setFieldValue("coPermitExpiryYear", currentApp.application.coApplicant.citizenshipTempExpire.getFullYear())
                                    }
                                    else if (currentApp.application.coApplicant.citizenshipPermit == 2) {
                                        setFieldValue("coPermitTypePermanent", currentApp.application.coApplicant.citizenshipPermPermit);
                                        setFieldValue("coPermitNumber", currentApp.application.coApplicant.citizenshipSerialNumber);
                                    }
                                }
                            }
                        }

                        if (currentApp.application.applicant.grossIncome > -1 && currentApp.application.step >= 3) {
                            setFieldValue("incomeGrossAmount", currentApp.application.applicant.grossIncome);
                            setFieldValue("incomeNetAmount", currentApp.application.applicant.nettoIncome);
                            setFieldValue("incomeOtherAmount", currentApp.application.applicant.otherIncome);
                            { currentApp.application.applicant.otherIncome > 0 ? setFieldValue("incomeOtherType", 1) : setFieldValue("incomeOtherType", 0) }
                            setFieldValue("householdDiscretionaryIncome", currentApp.application.discretionaryIncome);
                            setFieldValue("creditCardStatus", currentApp.application.creditCardStatus);
                            setFieldValue("mastercardStatus", currentApp.application.mastercard);

                            if (currentApp.application.coApplicant != null) {
                                setFieldValue("coIncomeGrossAmount", currentApp.application.coApplicant.grossIncome);
                                setFieldValue("coIncomeNetAmount", currentApp.application.coApplicant.nettoIncome);
                                setFieldValue("coIncomeOtherAmount", currentApp.application.coApplicant.otherIncome);
                                { currentApp.application.coApplicant.otherIncome > 0 ? setFieldValue("coIncomeOtherType", 1) : setFieldValue("coIncomeOtherType", 0) }
                            }
                        }
                        if (currentApp.application.insurance > -1 && currentApp.application.step >= 3) {
                            setFieldValue("householdInsurance", currentApp.application.insurance);
                            setFieldValue("householdCarsNumber", currentApp.application.nrOfCars);
                            setFieldValue("householdTransportationOtherExpense", currentApp.application.transportationOtherExpense);
                            for (let array = values.cars, i = 0; i < currentApp.application.nrOfCars; i++) {
                                var c: Car = {
                                    leaseAmount: currentApp.cars[i].leaseAmount,
                                    carType: currentApp.cars[i].carType,
                                }
                                array.push(c)
                            }
                        }
                        if (currentApp.application.step >= 8) {
                            setFieldValue("debtSinceLastTexReport", currentApp.application.applicant.debtSinceLastTaxReport);
                            { currentApp.application.applicant.debtSinceLastTaxReport > 0 ? setFieldValue("debtSinceJan", 1) : setFieldValue("debtSinceJan", 0) }

                            setFieldValue("householdOtherLoanRest", currentApp.application.otherLoan);
                            if (currentApp.application.otherLoan > 0) {
                                setFieldValue("otherLoan", 1);
                                setFieldValue("householdOtherLoanExpense", currentApp.application.otherLoanExpense);
                            }
                            else {
                                setFieldValue("otherLoan", 0)
                            }
                            setFieldValue("publicDebtRest", currentApp.application.publicDebt);

                            if (currentApp.application.publicDebt > 0) {
                                setFieldValue("publicDebt", 1);
                                setFieldValue("publicDebtExpense", currentApp.application.publicDebtExpense);
                            }
                            else {
                                setFieldValue("publicDebt", 0)
                            }

                            setFieldValue("carLoanRest", currentApp.application.carLoan);
                            if (currentApp.application.carLoan > 0) {
                                setFieldValue("carLoan", 1);
                                setFieldValue("carLoanExpense", currentApp.application.carLoanExpense);
                            }
                            else {
                                setFieldValue("carLoan", 0);
                            }

                            setFieldValue("studyLoanRest", currentApp.application.studyLoan);
                            if (currentApp.application.studyLoan > 0) {
                                setFieldValue("studyLoan", 1);
                                setFieldValue("studyLoanExpense", currentApp.application.studyLoanExpense);
                            }
                            else {
                                setFieldValue("studyLoan", 0);
                            }

                            setFieldValue("consumerLoanRest", currentApp.application.consumerLoan);
                            if (currentApp.application.consumerLoan > 0) {
                                setFieldValue("consumerLoan", 1);
                                setFieldValue("consumerLoanExpense", currentApp.application.consumerLoanExpense);
                            }
                            else {
                                setFieldValue("consumerLoan", 0);
                            }


                            setFieldValue("otherDebt", currentApp.application.otherDebt);
                            if (currentApp.application.otherDebt > 0) {
                                setFieldValue("hasOtherDebt", 1);
                                setFieldValue("otherDebtExpense", currentApp.application.otherDebtExpense);
                            }
                            else {
                                setFieldValue("hasOtherDebt", 0);
                            }

                            if (currentApp.application.coApplicant != null) {
                                setFieldValue("coDebtSinceLastTexReport", currentApp.application.coApplicant.debtSinceLastTaxReport);
                                { currentApp.application.coApplicant.debtSinceLastTaxReport > 0 ? setFieldValue("coDebtSinceJan", 1) : setFieldValue("coDebtSinceJan", 0) }
                            }

                        }

                        if (currentApp.application.step >= 9) {
                            setFieldValue("creditPaymentBankCode", currentApp.secureInfo.regNr);
                            setFieldValue("creditPaymentAccountNumber", currentApp.secureInfo.accountNr);
                            setFieldValue("bankSinceYear", currentApp.application.bankSinceYear);
                            setFieldValue("consent", true);
                            setFieldValue("facitConsent", true);
                            console.log("consent= " + values.consent)
                        }


                        setShowLoader(false);

                    }
                    else {
                        removeCookie("NotFinished");
                        window.location.href = "/";

                    }
                }

                // SCROLLS TO ERROR, IF USER HAS ERROR IN FORM
                const keys = Object.keys(errors);
                console.log(keys);
                if (keys.length > 0 && isSubmitting && !isValidating) {
                    const selector = `[id="${keys[0]}"]`;
                    const errorElement = document.querySelector(selector);
                    if (errorElement != null) {
                        errorElement.scrollIntoView({
                            block: "center"
                        })
                    }
                }

                if (cookie != undefined) {
                    window.onload = GetAppById;
                }
                else {
                    setShowLoader(false);
                }
                window.onpopstate = locationHasChanged;

                return (
                    <div>
                        {showWarning &&
                            <InUseCon>
                                <InUse>
                                    <img src="../images/warning.svg" />
                                 <h4>CPR-nummer er i brug</h4>
                                    <p>Dit CPR-nummer er allerede i brug i en anden låneansøgning. <br /> <br /> Indtast dit CPR-nummer på vores login-side og vi vil sende dig en adgangskode til at fortsætte din applikation <br /><br /> Hvis du er ejer af indtastet af CPR-nummer og har ikke lavet en applikation før, bedes du straks kontakte os</p>
                                <NextPopUp type="button" onClick={() => window.location.href = "/login"}>Gå til login<img src="../images/arrow-right-short-white.svg" /></NextPopUp>
                            </InUse>
                             
                            </InUseCon>
                        }
                        {!showLoader &&
                            <FormContainer />
                        }
                    </div>
                );
            }}
        </Formik>
    )
}


export default ApplicationForm;


