import React from "react";
import styled from "styled-components";
import { useFormikContext, Field, Form, Formik, FormikProps, FormikTouched } from 'formik';
import { LoanType, LoanReason } from "./Enums"
import { trackGaEvent } from "../../GA";
import * as yup from "yup";
import { apiConfiguration } from "../api-configuration";
import { LoanBrokerApi } from "../lib-api/apis/LoanBrokerApi";
import { validationSchema } from "./validation";
import { ApplicationApi } from "../lib-api/apis/ApplicationApi";
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import { LoginApi } from "../lib-api";
import { useCookies } from "react-cookie";
import MaskedInput from 'react-text-mask';
import { trackEvent } from "../util/gtm";
 
const MainContainer = styled("div")`
    text-align: center;
    color: #FCFCFC;
    padding-bottom: 150px;
    padding-top: 20px;
    min-height: 100vh;
@media only screen and (max-width: 500px) {
min-height: 0px;
padding-bottom: 0px;
}
    `

export const vw = window.screen.width;

const Headline = styled.h1`
font-size: 28px;
line-height: 55px;
margin-top: 120px;
text-align: left;
width: 80%;
font-weight: 600;
@media only screen and (max-width: 1000px) {
        margin-top: 0;

    }

@media only screen and (max-width: 800px) {
        font-size: 24px;
        text-align: center;
        margin: auto;

    }

`;

const HighlighedWord = styled.span`
font-weight: 800;
color: #14C6B1;
text-shadow: 0px 3px 6px #0000004D;
font-size: 35px;

@media only screen and (max-width: 800px) {
        font-size: 24px;
        text-align: center;

    }

`

const SubHeadline = styled.h3`
font-size: 17px;
line-height: 40px;
text-align: left;
font-weight: 300;
width: 75%;

@media only screen and (max-width: 800px) {
        margin: auto;
        width: 85%;
        text-align: center;

    }

`

const OrangeHeadline = styled("span")({
    color: "#EC5D35"
});

export const Container = styled.div`
    background-color: #FFFFFF;
    borderRadius: 5px;
    marginTop: 50px;
    box-shadow: 0px 10px 15px 0px #00000029;
    mos-box-shadow: 0px 10px 15px 0px #00000029;
    webkit-mox-shadow: 0px 10px 15px 0px #00000029;
    position: relative;
`

export const Loader = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 20;
    background-color: white;
    left: 0;
    padding-top: 250px;
    position: absolute;
    @media(max-width: 768px){
    img{
    position: fixed;
    left: 32%;
    }
    padding-top: 0px;
    }
`

const ResultContainer = styled.div`
   @media (max-width: 768px) {
        margin-top:0;
  }
`

export const Error = styled.div`
    position: absolute;
    font-style: italic;
    color: #E76F51;
    font-size: 11px;
    left: 7px;
    margin-top: 2px;
`

export const HeadlineContainer = styled("div")`
    img{
    width: 40%;
    height: auto;
    margin-top: 30px;
    @media only screen and (max-width: 800px) {
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 20px;

    }

    }
    text-align: left;

`

export const GoToBtn = styled.button`
position: absolute;
left: 20px;
margin-top: 5px;
top: 125px;
`


export function ThousandSeperator(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const Button = styled(styled("button")({
    fontSize: "22px",
    border: "none",
    padding: "12px 36px",
    width: "40%",
    margin: "auto",
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",

    ":focus": {
        outline: "none"
    }
}))`
    img {
    width: 15px;
    height: auto;
}
`

const Previous = styled(Button)`
    color: #007AB9;
    background: inherit;
    border-radius: 4px;

    img {
        transform: rotate(180deg)
    }

    @media (max-width: 768px) {
        border: 1px solid #007AB9;
        margin-bottom:10px;
    }
`;

export const Next = styled(Button)`
color: white;
background-color: #2A9D8F;
border-radius: 33px;
transition: 0.2s linear;

img{
transition: 0.2s linear;
}


&:hover{
transition: 0.2s linear;
background-color: #279285;
img{
transition: 0.2s linear;
margin-right: -5px;
margin-left: 5px;
}
}

@media only screen and (max-width: 800px) {
width: 80% !important;

padding: 12px 44px;
margin-top: 15px;
}
`

export const Login = styled(Next)`
width: 56%;
@media only screen and (max-width: 800px) {
width: 70% !important;
}
`

export const NextSmall = styled(Next)`
font-size: 19px;
    padding: 18px 20px;
    width: 53%;
margin-top: 60px;
margin-bottom: 60px;
`

const FormCon = styled.div`
    padding: 90px 30px 20px;
    border-radius: 25px;
    transition: 0.1s ease-in;
    position: relative;
    z-index: 2;
    color: #315665;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin-top: 120px;
    background-color: white !important;
@media only screen and (max-width: 500px) {
    padding: 80px 25px 20px !important;
    min-height: auto;
    margin-top: 30px;

    }

`

const FormTop = styled.div`
width: 100%;
position: absolute;
left: 0;
top: 0;
background-color: #315665;
border-radius: 22px 22px 0px 0px;
padding: 0 25px;
h2{
color: white;
font-size: 24px;
text-align: left;
margin: 25px 0;
font-weight: 600;
@media only screen and (max-width: 800px) {
font-size: 18px;
}
}
@media only screen and (max-width: 800px) {
padding: 0 35px;

}

`

const Input = styled.input`
border-radius: 6px;
border: 2px solid rgba(76, 76, 76, 0.4);
width: 100%;
margin: 15px auto;
padding: 15px;
outline: none;

&:focus{
    border-color: #2A9D8F;
    outline: none;

}
`

const TryAgain = styled.p`
    font-size: 14px;
    width: 20%;
    margin: auto;
    margin-top: 15px;
&:hover{
    cursor: pointer;
    text-decoration: underline;

}

@media only screen and (max-width: 800px) {
    width: 40%;
    text-align: center;
}
`

const InfoText = styled.p`
    font-size: 16px;
    width: 100%;
    margin: auto;
    text-align: left;
@media only screen and (max-width: 800px) {
    margin-top: 15px;

}
`
const ErrorCon = styled.div`
    position: absolute;
    color: #E76F51;
    margin-top: -14px;
    font-size: 12px;
    margin-left: 18px;
    font-style: italic;

`

interface FormValues {
    step: number;
    cpr: string;
    pinCode: string;



}

const LoginForm: React.FunctionComponent = () => {
    const [showCPRErrors, setShowCPRErrors] = React.useState(false);
    const [showPinErrors, setShowPinErrors] = React.useState(false);
    const [showUseOld, setShowUseOld] = React.useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

    const initialValues: FormValues = {
        step: 1,
        cpr: "",
        pinCode: "",
    };
    return (

        <Formik
            initialValues={initialValues}
            onSubmit={async (values, helpers) => {
                var api = new LoginApi(apiConfiguration);
                var appId;
                if (values.step == 1) {
                    var app = await api.apiLoginSendPinCodePut({
                        cpr: values.cpr
                    })
                    if (app == "") {
                        setShowCPRErrors(true);
                    }
                    else {
                        values.step++
                        setShowCPRErrors(false);
                    }
                }
                else if (values.step == 2) {
                      appId = await api.apiLoginValidatePinCodePut({
                        cpr: values.cpr,
                        pin: values.pinCode
                    })

                    if (appId != "") {

                        var Id = appId.replaceAll('"', '');

                        if (appId.includes("notFinished")) {
                            Id = Id.replace('notFinished', '')
                            setCookie("NotFinished", Id, { path: "/", maxAge: 3600 })
                            window.location.href = "/";
                        }
                        else {
                            setCookie("Validated", Id, { path: "/", maxAge: 3600 })

                            window.location.href = "/mypage";
                        }

                        trackEvent(
                            "Succesfuld login",
                            Id,
                            "undefined"
                        )
                    }
                    else {
                        setShowPinErrors(true);
                 
                    }
                }
            }}
        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                    isValid,
                    submitCount,
                    setFieldValue,
                    submitForm
                } = props;


                function makeCpr(event) {
                    var num = event.target.value;
                    num = num.replace(/[ \D]/ig, "");
                    if (num.toString().length == 10) {
                        values.cpr = num;
                    }
                }
                return (
                    <div>
                        <MainContainer className="form-background">
                            <div className="whole-container container">
                                <div id="formTop" />
                                <HeadlineContainer className="left-container">
                                    <Headline><HighlighedWord>Log ind</HighlighedWord></Headline>
                                    <SubHeadline>Fortsæt din ansøgning eller <br/>
                                    sammenlign dine lånetilbud</SubHeadline>
                                </HeadlineContainer>
                                <div className="right-container">
                                    <FormCon>
                                        <FormTop>
                                            {values.step == 1 && <h2>Indtast dit CPR-nummer</h2>}
                                            {values.step == 2 && <h2>Indtast din adgangskode</h2>}
                                        </FormTop>
                                        {values.step == 1 && <InfoText>Herefter modtager du en E-mail med en midlertidig adgangskode. </InfoText>}
                                        {values.step == 2 && <InfoText>Af sikkerhedsmæssige årsager er din adgangskode kun gyldig i en time, og du kan kun bruge den en gang. </InfoText>}
                                        {values.step == 1 &&
                                        <div><MaskedInput className="login-input" type="text" mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} id="cpr" onChange={(event) => makeCpr(event)} placeholder="Indtast CPR-nummer" />
                                        {showCPRErrors &&
                                                <ErrorCon>
                                                Indtastet CPR-nummer findes ikke i vores register
                                            </ErrorCon>
                                        }
                                            </div>
                                        }

                                        {values.step == 2 &&
                                            <div>
                                                <Input name="pinCode" id="pinCode" onChange={handleChange} placeholder="Indtast Adgangskode" />
                                        {showPinErrors &&
                                                    <ErrorCon>
                                                        Indtastet adgangskode er ikke korrekt
                                            </ErrorCon>
                                                }
                                            </div>
                                        }
                                        {values.step == 1 && <Next style={{width: "55%"}} type="submit" disabled={isSubmitting} onClick={submitForm}> Send kode <img src="../images/arrow-right-short-white.svg" /></Next>}
                                        {values.step == 2 && <Login type="submit" disabled={isSubmitting} onClick={submitForm} >Log ind <img src="../images/arrow-right-short-white.svg" /></Login>}
                                        {values.step == 2 && <TryAgain>Send igen</TryAgain>}
                                    </FormCon>
                                </div>
                            </div>
                        </MainContainer>
                    </div>
                );
            }}
        </Formik>
    )
}


export default LoginForm;